(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
// ------------------------------------
//
// Fetch posts via AJAX
//
// ------------------------------------

(function($) {

    if (typeof window.AJAXFetchPosts == 'undefined') window.AJAXFetchPosts = {};

    AJAXFetchPosts = {

        init: function() {
            // Initialize for ajax feed sections
            this.ajaxFeeds();

            // Wire up filters
            this.ajaxFilters();
        },

        // Fetch content via AJAX for feed rows
        ajaxFeeds: function(filters) {
            // Filters can be specified as a query string that will be passed to the API
            // Passing filter_workplace as a filter will store a cookie
            var thisRef = this;
            var url = filters ? "/wp-json/wp/v2/posts?" + filters : "/wp-json/wp/v2/posts";
            $('.js-ajax-fetch').each(function() {
                // Show a loader
                $(this).append('<div class="loading"></div>');
                $.ajax({
                    url: url,
                    context: $(this)
                }).done(function(data) {
                    // Remove slick if already loaded
                    if ($(this).hasClass("js-feed")) {
                        $(this).slick('unslick');
                    }

                    // Construct markup of cards
                    var markup = data.map(function(post) {
                        return thisRef.renderCard(post);
                    }).join("");
                    $(this).html(markup);

                    // Initialize slick if more than 3 elements
                    if (data.length > 3) {
                        $(this).addClass("js-feed");
                        window.Theme.initializeSlickForElement($(this));
                    }

                    // Remove loading classes from filters
                    $('.js-filter .remove-filter, .js-filter .select-overlay').removeClass("-load");
                });
            });
        },

        renderCard: function(post) {
            return post.rendered_card;
        },

        // Change filters to work via AJAX for sections
        ajaxFilters: function() {
            var thisRef = this;
            $('.section.-ajax .filters form').on('submit', function(event) {
                event.preventDefault();
                // Send this request to the JSON API instead
                var filters = $(this).serialize();
                thisRef.ajaxFeeds(filters);
            });
        }

    };

    module.exports = AJAXFetchPosts;

})(jQuery);

}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/ajax-fetch-posts.js","/modules")