(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
// ------------------------------------
//
// Scroll
//
// ------------------------------------

(function($) {

    if (typeof window.Scroll == 'undefined') window.Scroll = {};

    Scroll = {

        settings: {},

        /*
         * Scroll init
         */

        init: function() {

            // Global Objects
            Scroll.$win        = $(window);
            Scroll.$doc        = $(document);

            // Global Vars
            Scroll.scrolled    = Scroll.$win.scrollTop();
            Scroll.winHeight   = Scroll.$win.height();
            Scroll.docHeight   = Scroll.$doc.outerHeight();

            // Navbar
            this.navBar();

            // Scrolling Parrallax
            this.scrollParallax();

            // Scrolled from Top
            Scroll.$win.scroll(function(){
                Scroll.scrolled = Scroll.$win.scrollTop();
            });
        },


        // ------------------------------------
        // Scroll Distance
        // ------------------------------------

        scrollLimit: function($limit) {

            if ($limit) {

                if ( Scroll.scrolled >= $limit ) {
                    return true;
                } else {
                    return false;
                }

            } else {
                return Scroll.scrolled;
            }

        },


        // ------------------------------------
        // Shrinking Navbar
        // ------------------------------------

        navBar: function() {
            var $navBar   = $('#primary-navigation');
            if (!$navBar) return;

            Scroll.logoCircles();

            Scroll.$win.scroll(function(){
                var limit = Scroll.scrollLimit(Scroll.winHeight/10);

                Scroll.logoCircles();

                if ( limit ) {
                    $navBar.addClass('-shrink');
                } else {
                    $navBar.removeClass('-shrink');
                }
            });
        },

        logoCircles: function() {
            var percent = ( Scroll.scrolled / (Scroll.docHeight - Scroll.winHeight) ) * 100;
            var rotate  = percent * (360 / 100);

            // console.log(Scroll.docHeight);

            $('#circle-inner').css('transform','rotate(' + rotate*3 + 'deg)');
            $('#circle-mid').css('transform','rotate(' + rotate*2 + 'deg)');
            $('#circle-outer').css('transform','rotate(' + rotate + 'deg)');
        },


        // ------------------------------------
        // Scroll Parrallax
        // ------------------------------------

        scrollParallax: function() {

            var $elements = $('.js-parallax');
            if (!$elements) return;

            $elements.each(function() {
                var $el         = $(this);
                var direction   = $el.data('direction') == 'down' ? 1 : -1 ;
                var multi       = $el.data('speed');
                var dataLimit   = $el.data('limit') * direction;

                var id          = $el.data('scroll-target');
                var target      = id ? $('#' + id).offset().top : $el.offset().top;

                Scroll.$win.scroll(function(){
                    var scrolling    = target > 0 ? scrolled - (target - Scroll.winHeight) : scrolled;

                    if (scrolling < 0 ) return;
                    var transform   = scrolling*multi*direction;

                    $el.css( 'transform', 'translate3d(0,' + transform + 'px,0)' );

                });

            })

        },



    };

    module.exports = Scroll;

})(jQuery);

}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/scroll.js","/modules")