(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){

require('./modules/share');
require('./plugins/slick');
require('./modules/smart-resize');

var Util    	= require('./modules/util');
var Theme 		= require('./modules/theme');
var Scroll 		= require('./modules/scroll');
var JoinForm = require('./modules/join-form');
var AJAXFetchPosts = require('./modules/ajax-fetch-posts');

jQuery(document).ready(function($) {

	Theme.init();
	Scroll.init();
	JoinForm.init();
	AJAXFetchPosts.init();

	// Attach the share module to a click event
	$('a[data-share]').share({
		count: false
	});
});

}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_8a1ed64f.js","/")