(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
// ------------------------------------
//
// Join Form
//
// ------------------------------------

(function($) {

    if (typeof window.JoinForm == 'undefined') window.JoinForm = {};

    JoinForm = {
        init: function() {
            if (window.HTMLOutputElement === undefined) {
                Object.defineProperty(HTMLUnknownElement.prototype, 'value', {
                    get: function () {
                        if (this.tagName === 'OUTPUT') {
                            return this.textContent;
                        }
                    },
                    set: function (newValue) {
                        if (this.tagName === 'OUTPUT') {
                            this.textContent = newValue;
                        }
                    }
                });
            }

            let membershipTermElement = document.querySelector('[name="fld_2015291"]');

            if(membershipTermElement) {
                let observer = new MutationObserver( function(mutationsList, observer){
                    for(let mutation of mutationsList) {
                        if(mutation.type == 'attributes') {
                            document.getElementById('term-output').value = mutation.target.value;
                        }
                    }
                });

                observer.observe(document.querySelector('[name="fld_2015291"]'), { 'attributes': true })
            }
        }
    };

    module.exports = JoinForm;

})(jQuery);

}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/join-form.js","/modules")