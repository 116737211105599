(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
// ------------------------------------
//
// Theme
//
// ------------------------------------

(function($) {

	if (typeof window.Theme == 'undefined') window.Theme = {};

	Theme = {

		settings: {},

		/*
		 * Theme init
		 */

		init: function() {

			// Init

			// Filled Input
			this.inputs();

			// Modals
			this.toggleActive();
			this.close();

			// Slick Sliders
			this.sliders();

			// Filters
			this.filters();

			// Play Video
			this.playVid();

			// Custom Gravity Form
			// this.gforms();

			// Mobile Menu
			this.mobileMenu();

			// Block links
			this.blocks();

			//Member login form
			this.memberLogin();

			// Add Picker Date to Date inputs
			this.datePicker();

		},

		// ------------------------------------
		// Focus Class for Inputs
		// ------------------------------------

		inputs: function() {

			// On load, check if any input has content, mark it "filled"
			$.each( $('input'), function() {
				if ( $(this).val())  {
					$(this).addClass('-filled');
					$(this).parent().addClass('-field-filled');
				}
			});

			$('input').on('focus', function() {
				$(this).addClass('-filled');
				$(this).parent().addClass('-field-filled');
			});

		},

		// ------------------------------------
		// Toggle Active
		// ------------------------------------

		toggleActive: function() {

			$('.js-toggle').live('click', function(event) {
				event.preventDefault();
				var $this = $(this);
				var $parent = $this.parent('.js-toggle-group');
				var $target = $('#' + $this.attr('data-toggle-target'));

				$this.toggleClass('-active');

				if ($this.hasClass('ham')) {
					$this.siblings('.menu').toggleClass('-active')
				}

				if ($parent) {
					var index = $this.index();

					$parent.removeClass(function(index, className) {
						return (className.match(/(^|\s)active-\S+/g) || []).join(' ');
					}).addClass('active-' + (index + 1))
					$('.js-toggle').not($this).removeClass('-active');
				}

				if ($target) $target.toggleClass('-active');

				if ($this.next('form')) {
					$this.next('form').find('input:first-of-type').focus();
				}
			});
		},

		// ------------------------------------
		// Close
		// ------------------------------------

		close: function() {

			$('.js-close').live('click', function(e) {
					e.preventDefault();
					var $this = $(this);
					var $parent = $this.parents;
					var attr = $this.attr('data-close-target');
					var $target = $('#' + attr);

					if ($this.parents('.-active')) $this.parents('.-active').removeClass('-active');
					if ($this.parents('.js-toggle-group')) $this.parents('.js-toggle-group').find('.-active').removeClass('-active');
					$this.next('.frame').find('iframe').attr('src', '');

					if ($target) {
							$target.removeClass('-active');
							$('[data-toggle-target="' + attr + '"]').removeClass('-active');
					}
			});
		},


		// ------------------------------------
		// Sliders
		// ------------------------------------

		sliders: function() {

			var thisRef = this;

			$('.js-feed').each(function(key, item) {
				var $this = $(this);
				thisRef.initializeSlickForElement($this);
			});

			$('.js-feed-next').on('click', function(event) {
				event.preventDefault();
				var $this = $(this);
				var $feed = $this.parent('section').find('.js-feed');

				if ( !$this.is('.disable') ) window.location.href = $this.attr('href');

				$feed.on('afterChange', function(slide, obj) {
					if( (obj.slideCount - obj.options.slidesToScroll) <= obj.currentSlide ) $this.removeClass('disable');
				});


				$feed.slick('slickNext');
			});

		},

		initializeSlickForElement: function($this) {
			var data = JSON.parse($this.attr('data-slick'));

			var args = {
					dots: false,
					cssEase: 'cubic-bezier(.5,0,0,1)',
					appendArrows: $this,
			};

			args = Object.assign({}, args, data);
			$this.slick(args);

			$this.on('beforeChange', function(event, slick, currentSlide) {
					// console.log(currentSlide);
					$this.find('.slick-track').attr('data-current', currentSlide);
			});
		},

		filters: function() {

			function updateUI($parent) {
				// Updates the select overlay and remove filter buttons to reflect the value of the select
				// Also hides/shows the remove button/select overlay depending on whether the select is empty
				var label = $parent.find("option:selected").html();
				$parent.find(".select-overlay, .remove-filter .value").html(label);

				if ($parent.find('select').val() !== "") {
					$parent.find(".remove-filter").removeClass("-hide");
					$parent.find(".remove-filter").addClass("-load");
					$parent.find(".select-overlay").addClass("-hide");
				} else {
					$parent.find(".remove-filter").addClass("-hide");
					$parent.find(".select-overlay").addClass("-load");
					$parent.find(".select-overlay").removeClass("-hide");
				}
			}

			$('.js-filter select').on('change', function() {
				// Automatically submit the form on change
				var $form = $(this).closest('form');
				if ($form) $form.submit();

				var $parent = $(this).closest('.js-filter');
				updateUI($parent);
			});

			$('.js-remove-filter').on('click', function() {
				// Automatically submit the form on removed
				var $form = $(this).closest('form');
				var $parent = $(this).closest('.js-filter');
				$parent.find("select").val("");
				if ($form) $form.submit();
				updateUI($parent);
			});
		},


        // ------------------------------------
        // Use Pikadday for dates
        // ------------------------------------

        datePicker: function() {
			// $('input[type=date]').pikaday();
		},

        // ------------------------------------
        // Play Video
        // ------------------------------------

        playVid: function() {

            $('.js-play').on('click', function(e) {
                var $this       = $(this)
                var dataTarget  = $this.data('popup-target');
                var $target     = dataTarget ? $('#' + dataTarget) : $this.parent();
                var frameCon    = $target.find('.frame');
                var frame       = $target.find('iframe');
                var src         = frameCon.attr('data-video') ? frameCon.attr('data-video') : frame.attr('src');

                // console.log(frame);

                frameCon.attr('data-video', src);
                frame.attr('src', src + frameCon.attr('data-autoplay'));
            });

        },

		gforms: function() {
		},

		mobileMenu: function() {
			// Wire up hamburger
			$('.navigation-hamburger').on('click', function() {
				if ($(this).hasClass('-active')) {
					$(this).removeClass('-active');
					$('#menu-primary-navigation').removeClass('-active');
					$('nav.navigation').removeClass('-active');
				} else {
					$(this).addClass('-active');
					$('#menu-primary-navigation').addClass('-active');
					$('nav.navigation').addClass('-active');
				}
			});

			// Repeat top-level items in the submenu, allowing top level items to be clicked

            var $menu   = $('#menu-primary-navigation');
            if ($menu) {
				$('#menu-primary-navigation .menu-item-has-children').each(function(key, item) {
					var $link = $(item).find('a:first').clone().wrap('<li class="menu-item menu-item-mobile">').parent();
					var $submenu = $(item).find('.sub-menu');
					$submenu.prepend($link);
				});
			}

			// Add click listeners for expading submenus on mobile
			$('#menu-primary-navigation .menu-item-has-children > a').on('click', function(event) {
				// Only run this code when we're on an active mobile menu
				if ($('#menu-primary-navigation').hasClass('-active')) {
					var $sub = $(this).parent().find('.sub-menu');
					event.preventDefault();
					// console.log("Executing", this);

					if ( $sub.is('.-expanded') ) {
						$sub.removeClass('-expanded');
					} else {
						$('#menu-primary-navigation -expanded').removeClass('-expanded');
						$sub.addClass('-expanded');
					}
				}
			});
		},

		blocks: function() {
			$('.blocks .block, .blocks .card').on('click', function() {
				var url = $(this).find('a').attr('href');
				window.location = url;
			});
		},

		memberLogin: function() {
			$('.forgotten-password-button').on('click', function(e) {
				e.preventDefault();
				$('.forgotten-password-form').css('height', 'auto');
				var autoHeight = $('.forgotten-password-form').height();
				$('.forgotten-password-form').height(0).animate({height: autoHeight}, 1000);
			});
		}
	};

	module.exports = Theme;

})(jQuery);

}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modules/theme.js","/modules")